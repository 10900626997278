<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="同意售后申请"
    :visible.sync="show"
    width="500px"
  >
    <div v-loading="loading" class="content">
      <p class="tipsWrap">请输入门店退货所需的联系信息</p>
      <el-form
        style="margin-top: 20px; width: 300px"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="联系电话" prop="receiverPhone">
          <el-input
            type="number"
            v-model="form.receiverPhone"
            placeholder="请输入联系电话"
          />
        </el-form-item>

        <el-form-item label="联系人" prop="receiver">
          <el-input v-model="form.receiver" placeholder="请输入联系人" />
        </el-form-item>

        <el-form-item label="收货地址" prop="receiveAddr">
          <AreaSelect :value.sync="form.receiveAddr" />
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const _this = this

    return {
      rules: {
        receiveAddr: [
          { required: true, trigger: 'blur', message: '请选择收货地址' }
        ],
        address: [
          { required: true, trigger: 'blur', message: '请输入详细地址' }
        ],
        receiver: [
          { required: true, trigger: 'blur', message: '请输入联系人' }
        ],
        receiverPhone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!_this.$utils.isPhone(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      form: {
        receiveAddr: [],
        receiver: '',
        receiverPhone: '',
        address: ''
      },
      tableData: [],
      carList: [],
      loading: false
    }
  },

  methods: {
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.$prompt('请输入您的登录密码进行验证', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputType: 'password',
            inputPattern: /\S+/,
            inputErrorMessage: '请输入登录密码'
          })
            .then(async ({ value }) => {
              this.loading = true

              const sendData = {
                id: this.currentItem.id,
                password: value,
                address: this.form.address,
                receiver: this.form.receiver,
                receiverPhone: this.form.receiverPhone
              }

              if (this.form.receiveAddr && this.form.receiveAddr.length) {
                sendData.provinceId = this.form.receiveAddr[0]
                sendData.cityId = this.form.receiveAddr[1] || ''
                sendData.regionId = this.form.receiveAddr[2] || ''
              }

              try {
                await this.$api.afterOrderList.pass(sendData)
                this.$message.success('操作成功！')
                this.show = false
                this.$emit('getData')
              } catch (e) {
                console.log(e)
              } finally {
                this.loading = false
              }
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}

.tipsWrap {
  margin-bottom: 16px;
  font-weight: bold;
}

.emptyWrap {
  padding: 10px;
  text-align: center;
}
</style>
