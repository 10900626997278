<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'after-sale-order:exportAfterSaleOrder'"
        :disabled="!tableData.length"
        @click="exportData"
        type="primary"
      >
        下载报表信息
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="售后单号" prop="id">
          <el-input v-model="params.id" placeholder="售后单号" />
        </el-form-item>
        <el-form-item label="支付单号" prop="orderId">
          <el-input v-model="params.orderId" placeholder="支付单号" />
        </el-form-item>
        <el-form-item label="门店名称" prop="storesName">
          <el-input v-model="params.storesName" placeholder="门店名称" />
        </el-form-item>
        <el-form-item label="申请时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态" prop="state">
          <el-select v-model="params.state" clearable>
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="售后类型" prop="type">
          <el-select v-model="params.type" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column show-overflow-tooltip prop="id" label="售后单号" />
        <el-table-column show-overflow-tooltip prop="typeStr" label="售后类型">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          min-width="130"
          prop="orderId"
          label="关联支付订单编号"
        />
        <el-table-column
          show-overflow-tooltip
          prop="storesName"
          label="申请门店"
        />

        <el-table-column
          show-overflow-tooltip
          prop="statusStr"
          label="订单状态"
        />

        <el-table-column
          show-overflow-tooltip
          prop="applyTime"
          label="申请时间"
        />

        <el-table-column width="230" label="操作">
          <template slot-scope="{ row }">
            <template v-if="row.statusStr == '申请中'">
              <el-button
                v-auth="'after-sale-order:approveApply'"
                type="text"
                @click="passHandler(row)"
                size="mini"
              >
                同意申请
              </el-button>
              <el-button
                v-auth="'after-sale-order:rejectApply'"
                type="text"
                @click="rejectHandler(row)"
                size="mini"
              >
                拒绝申请
              </el-button>
            </template>
            <el-button
              v-auth="'supplier:confirmReceive'"
              @click="confirmHandler(row)"
              v-if="row.state == 1 && row.storeState == 2"
              type="text"
              size="mini"
            >
              确认收货
            </el-button>
            <el-button
              v-auth="'supplier:afterDeliver'"
              v-if="row.statusStr == '待发货'"
              @click="sendHandler(row, false)"
              type="text"
              size="mini"
            >
              发货
            </el-button>
            <!-- <el-button
              v-if="row.statusStr == '配送中'"
              @click="sendHandler(row, true)"
              type="text"
              size="mini"
            >
              配送信息
            </el-button> -->
            <el-button
              v-auth="'after-sale-order:getOrderDetail'"
              @click="detailHandler(row)"
              type="text"
              size="mini"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <SendModal
      v-if="visible"
      :visible.sync="visible"
      :currentItem="currentItem"
      :isDetail="isDetail"
      @getData="getData(true)"
    />
    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />

    <AgreeModal
      v-if="agreeVisible"
      :visible.sync="agreeVisible"
      :currentItem="currentItem"
      @getData="getData(true)"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import SendModal from './components/SendModal.vue'
import DetailModal from './components/DetailModal.vue'
import AgreeModal from './components/AgreeModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      isDetail: false,
      visible: false,
      agreeVisible: false,
      detailVisible: false,
      logisticsVisible: false,
      currentItem: null,
      loading: false,
      tableData: [],

      statusObj: {
        0: '申请中',
        1: '待收货',
        2: '待发货',
        3: '配送中',
        4: '已完成',
        5: '已拒绝'
      },

      typeObj: {
        3: '换货',
        1: '退款',
        2: '退货退款'
      },

      stateList: [
        {
          label: '申请中',
          value: '0'
        },
        {
          label: '待收货',
          value: '1'
        },
        {
          label: '待发货',
          value: '2'
        },
        {
          label: '配送中',
          value: '3'
        },
        {
          label: '已完成',
          value: '4'
        },
        {
          label: '已拒绝',
          value: '5'
        }
      ],
      typeList: [
        {
          label: '换货',
          value: '3'
        },
        {
          label: '退款',
          value: '1'
        },
        {
          label: '退货退款',
          value: '2'
        }
      ],
      multipleSelection: [],
      sendWay: false,
      params: {
        limit: 20,
        page: 1,
        id: '',
        orderId: '',
        state: '',
        storesName: '',
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    SendModal,
    DetailModal,
    AgreeModal
  },
  created() {
    if (this.$route.query.orderId) {
      this.params.id = this.$route.query.orderId
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => ({
        id: _.id,
        id: _.id
      }))
    },
    async confirmHandler(row) {
      if (row.typeStr == '退货退款') {
        this.$prompt(
          '请输入供应商账号密码',
          '确认后完成确认收货,请确认货物已送达',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputPattern: /\S+/,
            inputType: 'password',
            inputErrorMessage: '请输入供应商账号密码'
          }
        )
          .then(async ({ value }) => {
            this.loading = true
            try {
              await this.$api.afterOrderList.confirmReceive({
                id: row.id,
                password: value
              })
              this.$message.success('操作成功！')
              this.getData(true)
            } catch (e) {
              console.log(e)
            } finally {
              this.loading = false
            }
          })
          .catch(() => {})
      } else {
        try {
          await this.$confirm(
            `点击确认后完成确认收货,请确认货物已送达`,
            '提示',
            {
              type: 'warning',
              confirmButtonText: '确认',
              cancelButtonText: '取消'
            }
          )

          try {
            this.loading = true
            await this.$api.afterOrderList.confirmReceive({
              id: row.id
            })
            this.$message.success('确认收货成功！')
            this.getData(true)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        } catch (err) {}
      }
    },
    async passHandler(row) {
      if (row.typeStr == '退款') {
        this.$prompt('请输入供应商账号密码', '否同意对售后订单进行退款操作?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          inputPattern: /\S+/,
          inputType: 'password',
          inputErrorMessage: '请输入供应商账号密码'
        })
          .then(async ({ value }) => {
            this.loading = true
            try {
              await this.$api.afterOrderList.pass({
                id: row.id,
                password: value
              })
              this.$message.success('操作成功！')
              this.getData(true)
            } catch (e) {
              console.log(e)
            } finally {
              this.loading = false
            }
          })
          .catch(() => {})
      } else {
        this.currentItem = row
        this.agreeVisible = true

        // try {
        //   await this.$confirm(
        //     `是否同意对售后订单进行${row.typeStr || '申请'}操作?`,
        //     '提示',
        //     {
        //       type: 'warning',
        //       confirmButtonText: '确认',
        //       cancelButtonText: '取消'
        //     }
        //   )

        //   try {
        //     this.loading = true
        //     await this.$api.afterOrderList.pass({
        //       id: row.id
        //     })
        //     this.$message.success('操作成功！')
        //     this.getData(true)
        //   } catch (e) {
        //     console.log(e)
        //   } finally {
        //     this.loading = false
        //   }
        // } catch (err) {}
      }
    },
    rejectHandler(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputPattern: /\S+/,
        inputErrorMessage: '请输入拒绝原因'
      })
        .then(async ({ value }) => {
          this.loading = true
          try {
            await this.$api.afterOrderList.reject({
              id: row.id,
              rejectReason: value
            })
            this.$message.success('操作成功！')
            this.getData(true)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }
        if (params.date && params.date.length) {
          params.applyStartTime = this.$utils.dateFormat(params.date[0])
          params.applyEndTime = this.$utils.dateFormat(params.date[1])
        } else {
          params.applyStartTime = undefined
          params.applyEndTime = undefined
        }

        params.currentPage = params.page
        params.pageSize = params.limit

        const { date, limit, page, ...sendData } = params
        const res = await this.$api.afterOrderList.getList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.statusStr = this.statusObj[+item.state]
          item.typeStr = this.typeObj[+item.type]
          return item
        })
        this.totalCount = res.page.total
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    sendHandler(row, type) {
      this.currentItem = row
      this.isDetail = type
      this.visible = true
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.applyStartTime = this.$utils.dateFormat(params.date[0])
          params.applyEndTime = this.$utils.dateFormat(params.date[1])
        } else {
          params.applyStartTime = undefined
          params.applyEndTime = undefined
        }

        await this.$api.afterOrderList.exportList({
          applyStartTime: params.applyStartTime,
          applyEndTime: params.applyEndTime,
          id: params.id || undefined,
          orderId: params.orderId || undefined,
          storesName: params.storesName || undefined,
          state: params.state || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
